/**
 * Обрезка строки по словам
 * @param str
 * @param maxLen
 * @param separator
 * @param postfix
 * @returns {string|*}
 */
const cutString = (str, maxLen, separator, postfix) => {
	separator = separator || ' '
	if (str.length <= maxLen) return str
	return str.substr(0, str.lastIndexOf(separator, maxLen)) + postfix
}

export default cutString
