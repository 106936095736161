import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Tags.module.scss'
import Tag from 'components/Tag'

const b = bem.tags(css)

const Tags = ({ label, items, className, negative }) => {
	if (!items.length) return null
	return (
		<div className={b({ negative }, className)}>
			<div className={b('inner')}>
				{label && <div className={b('label')}>{label}:</div>}
				<div className={b('list')}>
					{items.map(({ key, name, url }) => (
						<Tag key={`tag-${key}`} url={url} negative={negative}>
							{name}
						</Tag>
					))}
				</div>
			</div>
		</div>
	)
}

Tags.propTypes = {
	negative: PropTypes.bool,
	label: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			name: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
}

export default Tags
