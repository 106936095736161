import React from 'react'
import PropTypes from 'prop-types'
import Tags from 'components/Tags'

const Categories = ({ post, className }) => (
	<Tags
		className={className}
		items={post.categories.nodes.map(({ categoryId: key, name, url }) => ({ key, name, url }))}
	/>
)

Categories.propTypes = {
	post: PropTypes.shape({
		categories: PropTypes.shape({
			nodes: PropTypes.arrayOf(
				PropTypes.shape({
					categoryId: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired,
					url: PropTypes.string.isRequired,
				}).isRequired
			).isRequired,
		}).isRequired,
	}).isRequired,
}

export default Categories
