import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Loop.module.scss'
import EntryShort from 'components/Post/EntryShort'
import EntryLeft from 'components/Post/EntryLeft'
import EntryRight from 'components/Post/EntryRight'
import Pagination from 'components/Pagination'
import Content from 'components/Content'
import arrayDistribute from 'utils/arrayDistribute'

const b = bem.loop(css)

const Loop = ({ pageContext }) => {
	const { posts, slug, type, number, isMore } = pageContext
	const { nodes } = posts

	// Распределенные по группам записи
	const nodesDist = nodes && arrayDistribute(nodes, [3, 2, 2])

	if (!nodes.length) return <p>Публикации появятся в ближайшее время</p>

	return (
		<Content className={b()}>
			<div className={b('list')}>
				<div className={b('list-inner')}>
					{nodesDist.map((part, i) => {
						const isSmall = !(i % 3) // 0 3 6
						const isMdLeft = !((i + 2) % 3) // 1 4 7
						const isMdRight = !((i + 1) % 3) // 2 5 8
						return (
							<div className={b('part')} key={`loop-${i}`}>
								{part.map((post, i) => {
									const props = { key: post.postId, post, isCategories: true, isExcerpt: true }
									if (isSmall || (isMdLeft && i === 1) || (isMdRight && i === 0))
										return <EntryShort className={b('entry-short')} {...props} />
									if (isMdLeft && i === 0) return <EntryLeft className={b('entry-left')} {...props} />
									if (isMdRight && i === 1) return <EntryRight className={b('entry-right')} {...props} />
									return null
								})}
							</div>
						)
					})}
				</div>
			</div>
			<div className={b('pagination')}>
				<Pagination {...{ slug, type, number, isMore }} />
			</div>
		</Content>
	)
}

Loop.propTypes = {
	pageContext: PropTypes.shape({
		posts: PropTypes.shape({
			nodes: PropTypes.arrayOf(
				PropTypes.shape({
					postId: PropTypes.number.isRequired,
				}).isRequired
			).isRequired,
		}).isRequired,
		slug: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		number: PropTypes.number.isRequired,
		isMore: PropTypes.bool.isRequired,
	}).isRequired,
}

export default Loop
