import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Thumbnail.module.scss'
import Img from 'gatsby-image'
import WithClassName from 'containers/WithClassName'

const b = bem.postThumbnail(css)

const Thumbnail = ({ post, gradient, className }) => {
	const { title, featuredImage } = post
	if (!featuredImage) return null
	return (
		<div className={b({ gradient }, className)}>
			<Img className={b('image')} fluid={featuredImage.imageFile.childImageSharp.fluid} alt={title} />
		</div>
	)
}

Thumbnail.propTypes = {
	post: PropTypes.shape({
		title: PropTypes.string.isRequired,
		featuredImage: PropTypes.shape({
			imageFile: PropTypes.shape({
				childImageSharp: PropTypes.shape({
					fluid: PropTypes.object.isRequired,
				}).isRequired,
			}).isRequired,
		}),
	}).isRequired,
	gradient: PropTypes.oneOf(['bottom', 'left', 'right']),
}

export default WithClassName(Thumbnail)
