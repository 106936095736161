import React from 'react'
import bem from 'bem'
import css from './Title.module.scss'

const b = bem.postTitle(css)

const Title = ({ children, className }) => {
	let size = 0
	const sizes = [18, 45, 79]
	const length = children.length
	sizes.map((s, index) => (size = length >= s ? index + 1 : size))

	return <h2 className={b({ size }, className)}>{children}</h2>
}

export default Title
