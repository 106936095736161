import React from 'react'
import bem from 'bem'
import css from './Category.module.scss'
import { default as Layout } from 'layouts/Main'
import Loop from 'components/Loop'
import SEO from 'components/SEO'

const b = bem.sceneCategory(css)

const Category = props => {
	const { pageContext } = props
	const { name, number, description } = pageContext
	const title = `${name}${number > 1 ? ` (часть ${number})` : ''}`
	return (
		<Layout
			className={b()}
			title={title}
			description={description ? description : `Последние записи категории &laquo;${title}&raquo;`}
			isPadding
		>
			<SEO title={`${title} — Парсинг сайтов «Парсик»`} description={description} />
			<Loop {...props} />
		</Layout>
	)
}

export default Category
