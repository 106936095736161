import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './EntryShort.module.scss'
import Link from 'gatsby-link'
import Thumbnail from 'components/Post/Thumbnail'
import Categories from 'components/Post/Categories'
import Excerpt from 'components/Post/Excerpt'
import Title from 'components/Post/Title'

const b = bem.postEntryShort(css)

const EntryShort = ({ post, className, isExcerpt, isCategories, isCentered }) => {
	const { title, url, featuredImage } = post
	return (
		<div className={b({ 'is-centered': isCentered }, className)}>
			<div className={b('inner')}>
				<Link className={b('link')} to={url} title={title}>
					{/* 100% высота изображения нужна для entry[left|right]. В этом контейнере нужно обернуть изображение в div чтобы не ломалась верстка, если родитель компонента флексовый */}
					{featuredImage && (
						<div className={b('thumbnail')}>
							<Thumbnail post={post} gradient="bottom" />
						</div>
					)}
					<div className={b('content')}>
						<Title className={b('title')}>{title}</Title>
						{isExcerpt && <Excerpt className={b('excerpt')} post={post} length={110} />}
					</div>
				</Link>
				{isCategories && <Categories className={b('categories')} post={post} />}
			</div>
		</div>
	)
}

EntryShort.propTypes = {
	post: PropTypes.shape({
		title: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		featuredImage: PropTypes.object,
	}).isRequired,
	isExcerpt: PropTypes.bool,
	isCategories: PropTypes.bool,
}

export default EntryShort
