import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Excerpt.module.scss'
import cutString from 'utils/cutString'
import stripTags from 'utils/stripTags'

const b = bem.postExcerpt(css)

const Excerpt = ({ post, length, className }) => {
	const { excerpt, content } = post
	const text = excerpt || content
	const __html = cutString(stripTags(text), length, null, ' ...')
	return <div className={b({}, className)} dangerouslySetInnerHTML={{ __html }} />
}

Excerpt.propTypes = {
	length: PropTypes.number.isRequired,
}

Excerpt.defaultProps = {
	length: 200,
}

export default Excerpt
