import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './EntryRight.module.scss'
import Link from 'gatsby-link'
import Thumbnail from 'components/Post/Thumbnail'
import Categories from 'components/Post/Categories'
import Excerpt from 'components/Post/Excerpt'
import Title from 'components/Post/Title'

const b = bem.postEntryRight(css)

const EntryRight = ({ post, className, isExcerpt, isCategories }) => {
	const { title, url, featuredImage } = post
	return (
		<div className={b({}, className)}>
			<div className={b('inner')}>
				{featuredImage && (
					<div className={b('thumbnail')}>
						<Link className={b('link')} to={url} title={title}>
							<Thumbnail classNameOutside={b('image')} post={post} gradient="left" />
						</Link>
					</div>
				)}
				<div className={b('content')}>
					<Link className={b('link')} to={url} title={title}>
						<div className={b('content-inner')}>
							<Title className={b('title')}>{title}</Title>
							{isExcerpt && <Excerpt className={b('excerpt')} post={post} length={200} />}
						</div>
					</Link>
					{isCategories && <Categories className={b('categories')} post={post} />}
				</div>
			</div>
		</div>
	)
}

EntryRight.propTypes = {
	post: PropTypes.shape({
		title: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		featuredImage: PropTypes.object,
	}).isRequired,
	isExcerpt: PropTypes.bool,
	isCategories: PropTypes.bool,
}

export default EntryRight
