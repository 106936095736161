/**
 * Распределение массива на подмассивы по количеству на основе правил
 * @param array
 * @param rules
 * @returns {[]}
 */
const arrayDistribute = (array, rules) => {
	let ruleIndex = 0
	let current = 0
	let row = []
	let res = []

	if (array.length <= rules[0]) res = [array]
	else {
		array.map(node => {
			if (current >= rules[ruleIndex]) {
				current = 0
				ruleIndex++
				res.push(row)
				row = []
			}

			row.push(node)
			current++

			if (ruleIndex >= rules.length) ruleIndex = 0

			return null
		})

		if (row.length) res.push(row)
	}

	return res
}

export default arrayDistribute
