import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Pagination.module.scss'
import route from 'utils/route'
import Button from 'components/Button'

const b = bem.pagination(css)

const Pagination = ({ slug, type, number, total, isMore, hash }) => {
	const postfix = hash ? `#${hash}` : ''
	const next = route[type]({ slug, number: number + 1 }) + postfix
	const prev = route[type]({ slug, number: number - 1 }) + postfix

	if (number === 1 && !isMore) return null

	return (
		<div className={b()}>
			{number > 1 ? (
				<Button isLink isNoBorder icon="prev" to={prev} title="Предыдущая страница" />
			) : (
				<div className={b('placeholder')} />
			)}
			<span className={b('current')}>
				{number}
				{total && (
					<>
						<span className={b('total-separator')}>из</span>
						{total}
					</>
				)}
			</span>
			{isMore ? (
				<Button isLink isNoBorder icon="next" to={next} title="Следующая страница" />
			) : (
				<div className={b('placeholder')} />
			)}
		</div>
	)
}

Pagination.propTypes = {
	slug: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	number: PropTypes.number.isRequired, // Номер текущей страницы
	total: PropTypes.number, // Общее число страниц
	isMore: PropTypes.bool.isRequired,
	hash: PropTypes.string,
}

export default Pagination
