import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Tag.module.scss'
import Button from 'components/Button'

const b = bem.tag(css)

const Tag = ({ url, children, negative }) => (
	<Button isLink to={url} title={children} className={b({ negative })} size="xs" theme={negative ? 'light' : 'default'}>
		{children}
	</Button>
)

Tag.propTypes = {
	negative: PropTypes.bool,
	url: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

export default Tag
